import React, { Component } from "react";
import styled, { css, keyframes } from "styled-components";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import {
  theme,
  Img,
  Text,
  Block,
  Actions,
  List,
  ListItem,
  Icon,
  Button,
} from "UI";

import YoutubeListItem from "./YoutubeListItem";
import EventListItem from "../EventContent/EventListItem";
import jsbridge from "utils/jsbridge";

const pulse = keyframes`
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1.0);
  }
`;
const animation = css`
  animation: ${pulse} 1.2s ease-in-out infinite;
`;

const ContentListItemContainer = styled(ListItem)`
  display: flex;
`;

const Thumb = styled(Img)`
  width: 14vw;
  border-radius: ${theme.borderRadius};
`;

const Texts = styled.div`
  padding: 0 ${theme.interval()};
  flex: 1;
`;

const Header = styled(Text.H2)`
  word-break: break-word;
`;

const IconWrap = styled(Block)`
  flex: 0 0 5vw;
  height: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.interval()};
  ${(p) => p.selected && animation}
`;

const Eye = styled(Icon)`
  font-size: 4vw;
`;

@inject("pageStore", "contentStore")
@observer
class ContentListItem extends Component {
  handlePress = async () => {
    const {
      content_online_class_present,
      content_newrow_present,
      content_category,
      content_id,
      history,
      content_youtube_id,
      location,
      content_type,
      match,
      ...rest
    } = this.props;
    const page = match.params.page;
    const { contentStore } = this.props;

    // document.withNavigation.shouldStoreFocusable(true)

    // if (content_newrow_present || content_category === 'newrow') {
    //   jsbridge.call('openPage', 'newrow')
    //   history.push(`/main/${page}/content/newrow/${content_id}/`)
    // } else if (content_type === 'event') {
    //   jsbridge.call('openPage', 'event')
    //   history.push(`/main/${page}/content/event/${content_id}/`)
    // } else {
    //   jsbridge.call('openPage', 'youtube')
    //   history.push(`/main/${page}/content/youtube/${content_id}/`)
    // }

    const { type, url, meeting_id, meeting_encrypted_password } =
      await contentStore.getContent(content_id);

    if (
      type === "newrow" ||
      type === "zoom_internal" ||
      type === "raw_zoom_external" ||
      type === "zoom_external"
    ) {
      jsbridge.call("openPage", "newrow");
      history.push(`/main/${page}/content/newrow/${content_id}/`);
    } else if (content_type === "event") {
      jsbridge.call("openPage", "event");
      history.push(`/main/${page}/content/event/${content_id}/`);
    } else {
      jsbridge.call("openPage", "youtube");
      history.push(`/main/${page}/content/youtube/${content_id}/`);
    }
  };

  render() {
    const {
      content_type,
      content_online_class_present,
      selected,
      content_header,
      content_markup,
      content_youtube_id,
      content_thumbnail,
      content_price,
      content_start_date,
      content_newrow_present,
      content_category,
      ...rest
    } = this.props;
    const content = {
      content_online_class_present,
      selected,
      content_header,
      content_markup,
      content_youtube_id,
      content_thumbnail,
      content_price,
      content_start_date,
      content_newrow_present,
      content_category,
    };

    return (
      <ContentListItemContainer onPress={this.handlePress} {...rest}>
        {content_type === "event" && <EventListItem {...content} />}
        {content_type !== "event" && <YoutubeListItem {...content} />}
      </ContentListItemContainer>
    );
  }
}

export default withRouter(ContentListItem);
